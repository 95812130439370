import { render, staticRenderFns } from "./ProductSmartDT.vue?vue&type=template&id=68498534&scoped=true&"
import script from "./ProductSmartDT.vue?vue&type=script&lang=js&"
export * from "./ProductSmartDT.vue?vue&type=script&lang=js&"
import style0 from "./ProductSmartDT.vue?vue&type=style&index=0&id=68498534&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68498534",
  null
  
)

export default component.exports